<script lang="ts">
  import { TinySlider } from "svelte-tiny-slider";

  const testimonials = [
    {
      name: 'Anthony Abgator',
      role: '🇳🇬 Software engineer',
      image: 'remote-software-engineer-nigeria',
      quote: 'As a software engineer with 8 years of experience, Jobgether is the best job platform I\'ve used. I secured my last job thanks to it. The platform\'s matching algorithm is incredibly accurate, and it aggregates new postings faster than any other site. The Priority Access feature is fantastic. While the Premium service could be a bit cheaper, the benefits make it worthwhile.'
    },
    {
      name: 'Alice Bangyozova',
      role: '🇧🇬 Customer Success',
      image: 'remote-customer-success-bulgaria',
      quote: 'I found my current job with Jobgether. I am a big fan of Jobgether - most of the job search websites are useless because of the location issue. I recommend it to a lot of my friends, as Jobgether is the only platform where I can see all the companies hiring in my location'
    },
    {
      name: 'Peter Lytras',
      role: '🇬🇷 Senior Sales Manager',
      image: 'remote-senior-sales-manager-greece',
      quote: 'Jobgether website is simple and easy to navigate, compared to other remote job platforms. The search results are really relevant. I\'ve chosen an annual plan, as I\'m interested in discovering which companies are hiring remotely in my country'
    },
    {
      name: 'Rachit Malik',
      role: '🇮🇳 Growth Manager',
      image: 'remote-growth-manager-india',
      quote: 'The job listings on Jobgether are really different from what I see on other platforms. I saw real value in the quality and quantity of the jobs - many more postings compared to others. All job offers are remote, which is convenient'
    },
  ];

  $: currentItem = 0;
  const sliderSettings = {
    gap: '40px',
    fill: true,
    transitionDuration: 300,
    threshold: 30,
  };

  function updateCurrentItem(index: number) {
    currentItem = index;
  }

  function getSlideWidth(sliderWidth: number): string {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1024) { return `${sliderWidth / 4}px`; }
    else if (windowWidth >= 768) { return `${sliderWidth / 3}px`; }
    else { return '272px'; }
  }

  function prevSlide(setIndex: TinySlider['setIndex'], index: number) {
    return () => setIndex(Math.max(index - 1, 0));
  }

  function nextSlide(setIndex: TinySlider['setIndex'], index: number) {
    return () => setIndex(Math.min(index + 1, testimonials.length - 1));
  }
</script>

<section id="testimonials" class="py-8 mb-16">
  <h2 class="text-2xl font-bold mb-8 text-primary container mx-auto">What our members say</h2>

  <div
    class:frist={currentItem === 0}
    class:last={currentItem >= testimonials.length - 3}
  >
    <TinySlider
      let:sliderWidth
      {...sliderSettings}
      on:change={(event) => updateCurrentItem(event.detail)}
    >
      {#each testimonials as testimonial}
      <div style={`width: ${getSlideWidth(sliderWidth)}`}>
        <div class="flex flex-col h-full bg-blue-4 items-center px-4 py-6 rounded-lg">
          <img
            src={`/astro/images/home/${testimonial.image}@3x.webp`}
            srcset={`/astro/images/home/${testimonial.image}.webp 1x, /astro/images/home/${testimonial.image}@2x.webp 2x, /astro/images/home/${testimonial.image}@3x.webp 3x`}
            alt={testimonial.name}
            class="w-24 h-24 rounded-full mb-4"
          />
          <h3 class="text-xl font-semibold text-primary mb-2">{testimonial.name}</h3>
          <p class="text-sm text-gray-3 mb-5">{testimonial.role}</p>
          <blockquote class="relative text-primary text-left pl-4 text-[14px] font-roboto">
            <span class="absolute top-0 left-0">❝</span>
            {testimonial.quote}
          </blockquote>
        </div>
      </div>
      {/each}
      <svelte:fragment slot="controls" let:setIndex let:currentIndex>
        <div class="text-right pr-2 md:pr-40 pt-3">
          <button
            on:click={prevSlide(setIndex, currentIndex)}
            class="p-1"
            class:opacity-50={currentIndex === 0}
            disabled={currentIndex === 0}
          >
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5 1L1.5 8L8.5 15" stroke="#023D51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button
            on:click={nextSlide(setIndex, currentIndex)}
            class="p-1"
            class:opacity-50={currentIndex >= testimonials.length - 3}
            disabled={currentIndex >= testimonials.length - 3}
          >
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 15L8.5 8L1.5 1" stroke="#023D51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </svelte:fragment>
    </TinySlider>
  </div>
</section>

<style>
  :global(.slider-content){
    align-items: stretch !important;
  }
  :global(#testimonials .slider::before, #testimonials .slider::after) {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
  }
  :global(#testimonials .slider::before){
    z-index: 1;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0, #FFF 70%);
    left: -12px;
    width: 0;
  }
  :global(#testimonials .slider::after){
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0, #FFF 70%);
    right: -12px;
    width: 8%;
  }
  :global(#testimonials .frist .slider::before){
    display: none;
  }
  :global(#testimonials .last .slider::after){
    display: none;
  }

  @media (min-width: 768px) {
    :global(#testimonials .slider::before){
      width: 8%;
    }
  }
</style>